<template>
  <div>
    <p>The Crux Prime release is full of good stuff, so it is going to take some extra time downloading it all!<u>
      <br>
      <br>Crux Prime</u>
      <br>A sea of Maelstrom pounds against the desolate shores of Crux Prime. Its goal: to destroy the construction of Nexus Tower – and the beam of Imagination which the Tower will protect!
      <br>
      <br>Team up for battle in the largest world yet discovered in LEGO Universe – and hold back the endless swarms of Maelstrom invaders until the Tower can be completed!
      <br>
      <br>- 50+ new Missions and Achievements!
      <br>- Innumerable new pieces of gear, decals, weapons and consumables to collect!
      <br>- New armor sets like the Bat Lord or Mosaic set!
      <br>- Gear up with alternate-color Faction Rank 3 Kits like the Black Knight, Red Sorcerer or Golden Samurai!
      <br>
      <br>Visit Nexus Force Guard Kurt Tussle at the Race Place in Nimbus Station to get to Crux Prime!
    </p>
    <p>
      <u>Ninjago Spins Up!</u>

      <br>Skeletons have invaded Crux Prime – dangerous enemies who can only be destroyed by one thing: Spinjitzu!
      <br>You must seek out Sensei Wu in the ruins of the Ninjago Monastery to learn this powerful new skill. Then transform into a whirling tornado of pure Imagination and smash those Skeletons into bones and dust!
      <br>
      <br>This first chapter of Ninjago features:
      <br>
      <br>- The start of an epic storyline which continues throughout the year.
      <br>- Imagination Spinjitzu skill and gear.
      <br>- New Skeleton creatures to battle.
      <br>- Bony treasures to unearth, including the Skeleton Fort Model Set, 3 Skeleton Rockets, a Skeleton Car and even a Skeleton Dragon Pet to help you dig up the bones you’ll need!
    </p>
    <p>
      <u>Teams Feature</u>
      <br>Up to 4 players can now join a team and battle together throughout LEGO Universe! Invite your friends and other brave Minifigures to team up, set loot options and then watch each others’ backs as you take on the Maelstrom threat!
    </p>
    <p>
      <u>Avant Grove Property</u>
      <br>Big news for builders! A new, larger Property world has been discovered off of Avant Gardens! Do you have the bricks and Imagination to populate it? Clear the Maelstrom from the Avant Grove world, then settle it and start building your grandest creations yet! Rocket off from the picnic area in Avant Garden.
    </p>
    <p>
      <u>Want to Meet Up with a certain Friend?</u>
      <br>Now you can! Open the world map by clicking the Map icon at the bottom of your screen. Here, you can chose between your friends who are in the same world as you, and be teleported to the same instance as your friend!
    </p>
    <p>
      <u>Zzzzzz</u>
      <br>You will be kicked back to the login screen, when you’ve been inactive for 15 minutes. After 5 minutes, your Minifigure will become impatient, after 10 minutes it will lie down for a nap, and after 15 minutes you will be back at the login screen.
    </p>
    <p>
      <u>Delete Stacks</u>
      <br>It is now possible to trash stacks of items, all in one go. Drag them from your Backpack to the trash can, and you’ll get to select how many you want to throw away.
    </p>
    <p>
      <u>Mission Complete!</u>
      <br>- The “Street Sweeper” Achievement can now be completed.
      <br>- The last part of the “Go Outside and Play” can now be completed.
      <br>- The “A Collector’s Dream” can be completed by Quick Building the Sirens who require a head in Gnarled Forest and the lion head Quick Builds at Cavalry Hill in Forbidden Valley.
    </p>
    <p>
      <u>Items which Lock on Pickup</u>
      <br>Several items, including the Ogre Buckler, the Lucky Woodman's Axe and the Maelstrom Eye Shield are now locked to your Minifigure when it’s picked up, instead of when it’s equipped.
    </p>
    <p>
      <u>Racing</u>
      <br>- There are now overhead rank indicators when you race.
      <br>- Racing specific missions will now show up while you are racing, so you can keep an eye on how you’re doing.
      <br>- The position of everyone in your race will now be displayed in the corner of your screen while racing.
    </p>
    <p>
      <u>Shooting Gallery</u>
      <br>Shooting Gallery specific missions will now be displayed while you’re sinking ships, so you can keep an eye on your progress.
    </p>
    <p>
      <u>Special Faction Skills</u>
      <br>- Sorcerers, after using the powerful Rank 3 double-jump attack, you’ll now have to wait a moment before you can attack again.
      <br>- Venture League members, the Venture “Lucky” skill will only work if you’re wearing all 6 pieces of Faction gear, not 5.
      <br>- When holding the Summoner Rank 1 staff, it does not matter how long you hold the charger, the effect will be the same.
    </p>
    <p>
      <u>Pets</u>
      <br>- We have adjusted the number of each type of pet wandering about. For example, now there are only three Rabbits, Cats and Dogs wandering about in Pet Cove.
      <br>- The way pets interact with the environment, bouncers and chests have been updated.
    </p>
    <p>
      <u>Maelstrom Horsemen</u>
      <br>You should no longer be chain-stunned by Maelstrom Horsemen, preventing you from reacting. That’s just not fair.
    </p>
    <p>
      <u>Token Drop Rates</u>
      <br>Token drops for Admirals and Apes have been balanced – it has been lowered for Admirals and turned up for Apes.
    </p>
    <p>
      <u>Gems</u>
      <br>Wanna trade rocks? Gems are now tradable.
    </p>
    <p>
      <u>News Screen</u>
      <br>The featured news on the News Screen now changes every 12 seconds. Use the arrows on either side to browse through them!
    </p>
    <p>
      <u>Glowing Maelstrom Anvil</u>
      <br>The Anvil in Nimbus Plaza now requires 12 Imagination to Quick Build.
    </p>
    <p>
      <u>Behaviors</u>
      <br>You can see Behavior tooltips, if you click the small (+)-buttons on the behavior list. In addition, we have fixed the placement of the behavior slots.
    </p>
    <p>
      <u>Models</u>
      <br>- Reward models have been modified and will be less "knobby" to walk across. To put this into effect, you'll need to go to your property, pick up the model and place it again!
      <br>- The Fort Model Set&nbsp;can no longer be purchased from Autumn Helix. Look for new cool Model Sets&nbsp;from the model vendors soon!
    </p>
    <p>
      <u>Boss Health Bars</u>
      <br>The Bosses health bars show up correctly, all the time.
    </p>
    <p>
      <u>LEGO Club</u>
      <br>You no longer receive a LEGO Club book in your in-game mail, when you link your LEGO Club account and your LEGO Universe account. Instead, you can walk through the portal, as soon as you’ve registered.
    </p>
  </div>
</template>
